import React from 'react';
import Page from 'src/components/Page';
import {
    Box,
    Typography,
    makeStyles,
    Grid,
    Paper,
    CardMedia,
} from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
    root: {
        overflow: "hidden"
    },
}));

function ContactUsDashboard() {
    const classes = useStyles();
    const [widthScreen, setWidthScreen] = useState(window.innerWidth)

    function resizeListener() {
        setWidthScreen(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", resizeListener);
        return () => window.removeEventListener("resize", resizeListener);
    }, [])

    return (
        <Page
            className={classes.root}
            title="ContactUs"
        >
            <Box mb={1} style={{ marginTop: widthScreen < 1280 ? '35px' : "110px", display: "flex", justifyContent: "center", padding: 5, width: "100vw" }}>
                <Paper elevation={0} style={{ display: "flex", flexDirection: "column", backgroundColor: "#fff", width: "100%", maxWidth: "1140px" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <CardMedia
                                src={require("./../HomeView/slideimages/location.png")}
                                alt="Cover"
                                component={'img'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box mt={2} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                <Typography variant='h5'>
                                    <b>Name:</b> RHU Plaridel
                                </Typography>
                                <Typography variant='h5'>
                                    <b>Location:</b> Plaridel Bulacan
                                </Typography>
                                <Typography variant='h5'>
                                    <b>Contact Number:</b> 09204886415
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper >
            </Box >
        </Page >
    );
}

export default ContactUsDashboard;
