import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  // Hidden,
  Menu,
  MenuItem,
  // Typography,
  makeStyles
} from '@material-ui/core';
import { logout } from 'src/actions/accountActions';

const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 28,
    width: 28
    // marginRight: theme.spacing(1)
  },
  popover: {
    width: 120,
    // border: '1px solid red',
    marginTop: 5,
    marginLeft: -5
  }
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
        // style={{ border: '1px solid red' }}
      >
        <Avatar
          alt={account.user.firstname}
          className={classes.avatar}
          src={`${imageLocation}avatar/${account.user.avatar}`}
        />
        {/* <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${account.user.firstname}`}
          </Typography>
        </Hidden> */}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        // style={{ border: '1px solid red' }}
      >
        {/* <MenuItem
          component={RouterLink}
          to="/app/social/profile"
        >
          Profile
        </MenuItem> */}
        {/* //redirect to account */}
        {/* {console.log('account.user', account.user.roles[0].name)} */}
        <MenuItem
          component={RouterLink}
          to={
            account.user && account.user.roles[0].name === 'admin'
              ? '/admin/account'
              : '/patient/account'
          }
          className="menuitemtopbar"
        >
          Account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} className="menuitemtopbar">
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
