import React from 'react';
import Page from 'src/components/Page';
import {
    Box,
    Typography,
    makeStyles,
    Grid,
    Paper,
    CardMedia,
} from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
    root: {
        overflow: "hidden"
    },
}));

function AboutUsDashboard() {
    const classes = useStyles();
    const [widthScreen, setWidthScreen] = useState(window.innerWidth)

    function resizeListener() {
        setWidthScreen(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", resizeListener);
        return () => window.removeEventListener("resize", resizeListener);
    }, [])

    return (
        <Page
            className={classes.root}
            title="AboutUs"
        >
            <Box mb={1} style={{ marginTop: widthScreen < 1280 ? '35px' : "110px", display: "flex", justifyContent: "center", padding: 5, width: "100vw" }}>
                <Paper elevation={0} style={{ display: "flex", flexDirection: "column", backgroundColor: "#fff", width: "100%", maxWidth: "1140px" }}>
                    <CardMedia
                        src={require("./../HomeView/slideimages/image4.png")}
                        alt="Cover"
                        component={'img'}
                    />
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    Growing with experienced people serves our mission of rendering quality services and ensuring all our patients get the best in patient care. Our aim is to uphold the sanctity of life and exceed the expectations of our patients and fulfill their needs. RHU 1 is focused on superior service, putting the patient at the center of care.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </Page>
    );
}

export default AboutUsDashboard;
