// eslint-disable no-use-before-define
// eslint-disable react/prop-types
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import { Activity } from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, item, depth = 0 }) {
  const key = item.title + depth;
  acc.push(
    <React.Fragment key={key}>
      {/* {console.log("item.owner", item.owner)}
      {console.log("item.currentrole", item.currentrole)} */}
      <NavItem
        // style={{ display: item.currentrole === 'admin' ? "block" : item.owner === item.currentrole ? "block" : item.owner === 'all' ? "block" : "none" }}
        style={{
          display:
            item.currentrole === 'admin'
              ? 'block'
              : item.owner === item.currentrole
              ? // item.currentrole !== 'user-inhouse' ?
                'block'
              : // : "none"
              item.owner === 'personnel'
              ? item.currentrole === 'registrar'
                ? 'block'
                : item.currentrole === 'registrar-reviewer'
                ? 'block'
                : item.currentrole === 'treasurer'
                ? 'block'
                : 'none'
              : item.owner === 'officer'
              ? item.currentrole !== 'applicant'
                ? 'block'
                : 'none'
              : item.owner === 'all'
              ? 'block'
              : 'none'
        }}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
        enabled={item.enabled}
      />
    </React.Fragment>
  );

  return acc;
}

const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);

  // console.log('openMobile: ', openMobile);

  const navConfig = [
    //admin
    {
      subheader: '',
      owner: 'admin',
      items: [
        {
          title: 'Transaction',
          icon: Activity,
          href: '/admin/dashboard',
          enabled: true
        },
        {
          title: 'Referential',
          icon: Activity,
          href: '/admin/dashboard',
          enabled: true
        },
        {
          title: 'Process',
          icon: Activity,
          href: '/admin/dashboard',
          enabled: true
        },
        {
          title: 'Reports',
          icon: Activity,
          href: '/admin/dashboard',
          enabled: true
        },
        {
          title: 'Setup',
          icon: Activity,
          href: '/admin/dashboard',
          enabled: true
        }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      // style={{ border: '1px solid red' }}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        {/* {console.log('PIL')} */}
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink
              to={
                user.roles[0].name.trim() === 'admin'
                  ? '/admin/account'
                  : '/patient/account'
              }
            >
              <Avatar
                alt={user.firstname}
                className={classes.avatar}
                src={`${imageLocation}avatar/${user.avatar}`}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to={
                user.roles[0].name.trim() === 'admin'
                  ? '/admin/account'
                  : '/patient/account'
              }
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstname} ${user.lastname}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig
            // .filter((x) => (user.roles[0].name.trim() === 'admin' ? user.roles[0].name.trim() : user.roles[0].name.trim() === 'doctor' ? user.roles[0].name.trim() : "user") === x.owner)
            .filter(x => user.roles[0].name.trim() === x.owner)
            .map((config, index) => (
              <List
                key={index}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
        </Box>
        <Divider />
        <Hidden xsUp xsDown>
          <Box p={1}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textSecondary" align="center">
                To register your property? Click{' '}
              </Typography>

              <Box mt={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/app/property/register"
                  fullWidth
                >
                  {' '}
                  Register{' '}
                </Button>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      {/* <Hidden mdDown> */}
      {/* <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        > */}
      {/* {content} */}
      {/* </Drawer> */}
      {/* </Hidden> */}
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
