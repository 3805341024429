import React from 'react';
import Page from 'src/components/Page';
import { Formik } from 'formik';
import axios from 'axios';
import { TimePicker, DatePicker } from '@material-ui/pickers';
import {
    Box,
    Typography,
    TextField,
    Button,
    // Divider,
    // FormControlLabel,
    // FormHelperText,
    // Switch,
    makeStyles,
    // FormGroup,
    // FormLabel,
    Grid,
    // Checkbox,
    Paper,
    CardMedia,
    Table,
    // TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
// import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
    root: {
        overflow: "hidden"
    },
}));

function AppointmentDashboard() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [widthScreen, setWidthScreen] = useState(window.innerWidth)
    const [locationList, setLocationList] = useState({ data: [], ready: false });
    const [fetchServices, setFetchServices] = useState({ data: [], ready: false });
    const [fetchWorkers, setFetchWorkers] = useState({ data: [], ready: false });
    const isMountedRef = useIsMountedRef();

    const [locationsName, setLocationsName] = useState(null)
    const [servicesName, setServicesName] = useState(null)
    const [workersName, setWorkersName] = useState(null)

    function resizeListener() {
        setWidthScreen(window.innerWidth)
    }

    const getLocationList = useCallback(() => {
        axios.get(`home/get/free-location`).then(response => {
            if (isMountedRef.current) {
                setLocationList({ data: response.data, ready: true });
            }
        });
    }, [isMountedRef]);

    const handleFetchService = (location_id, setFieldValue) => {
        var params = { location_id }
        setFieldValue("service_id", "")
        setFetchServices({ ...fetchServices, data: [], ready: false })
        setFieldValue("worker_id", "")
        setFetchWorkers({ ...fetchWorkers, data: [], ready: false })
        axios.get("home/get/free-services", { params })
            .then((response) => {
                const data = response.data;
                setFetchServices({ data, ready: true });
            })
            .catch(error => console.log("Error: ", error))
    }

    const handleFetchWorker = (service_id, setFieldValue) => {
        var params = { service_id }
        setFieldValue("worker_id", "")
        setFetchWorkers({ ...fetchWorkers, data: [], ready: false })
        axios.get("home/get/free-workers", { params })
            .then((response) => {
                const data = response.data;
                setFetchWorkers({ data, ready: true });
            })
            .catch(error => console.log("Error: ", error))
    }

    useEffect(() => {
        getLocationList()

        window.addEventListener("resize", resizeListener);
        return () => window.removeEventListener("resize", resizeListener);
    }, [getLocationList])

    return (
        <Page
            className={classes.root}
            title="Appointment"
        >
            <Box mb={1} style={{ marginTop: widthScreen < 1280 ? '35px' : "110px", display: "flex", justifyContent: "center", padding: 5, width: "99vw" }}>
                <Paper elevation={0} style={{ display: "flex", flexDirection: "column", backgroundColor: "#fff", width: "100%", maxWidth: "1140px" }}>
                    <CardMedia
                        src={require("./../HomeView/slideimages/image5.png")}
                        alt="Cover"
                        component={'img'}
                    />
                    <Formik
                        initialValues={{
                            location_id: "",
                            service_id: "",
                            worker_id: "",
                            date: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm:ss'
                            ),
                            time_from: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm:ss'
                            ),
                            time_to: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format(
                                'YYYY-MM-DD HH:mm:ss'
                            ),
                            patient_email: "",
                            patient_name: "",
                            patient_contact_no: "",
                            patient_description: "",
                        }}
                        validationSchema={Yup.object().shape({
                            location_id: Yup.string().required('Required'),
                            service_id: Yup.string().required('Required'),
                            worker_id: Yup.string().required('Required'),
                            date: Yup.date("Required"),
                            // time_from: Yup.time("Required"),
                            // time_to: Yup.time("Required"),
                            patient_email: Yup.string().email("Invalid email").required('Required'),
                            patient_name: Yup.string().required('Required'),
                            patient_contact_no: Yup.string().required('Required'),
                            patient_description: Yup.string().required('Required'),
                        })}
                        onSubmit={async (
                            values,
                            { resetForm, setErrors, setStatus, setSubmitting }
                        ) => {
                            let data = {
                                ...values,
                                date: moment(values.date, 'YYYY-MM-DD').format(
                                    'YYYY-MM-DD'
                                ),
                                time_from: moment(values.time_from, 'HH:mm:ss').format(
                                    'HH:mm:ss'
                                ),
                                time_to: moment(values.time_to, 'HH:mm:ss').format(
                                    'HH:mm:ss'
                                ),
                            };
                            try {
                                const response = await axios.post('home/create/free-appointment', {
                                    location_id: data.location_id,
                                    service_id: data.service_id,
                                    worker_id: data.worker_id,
                                    date: data.date,
                                    time_from: data.time_from,
                                    time_to: data.time_to,
                                    patient_email: data.patient_email,
                                    patient_name: data.patient_name,
                                    patient_contact_no: data.patient_contact_no,
                                    patient_description: data.patient_description,
                                });
                                if (response.data.message === 'success') {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                    enqueueSnackbar('Appointment successfully created.', {
                                        variant: 'success'
                                    });
                                    resetForm()
                                    setFetchServices({ ...fetchServices, data: [], ready: false })
                                    setFetchWorkers({ ...fetchWorkers, data: [], ready: false })
                                    setLocationsName(null)
                                    setServicesName(null)
                                    setWorkersName(null)
                                }
                            } catch (error) {
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setFieldTouched,
                            setFieldValue,
                            touched,
                            values
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mt={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box>
                                                <Typography variant='h4' style={{ fontWeight: "bold" }}>
                                                    Appointment Setup
                                                </Typography>
                                            </Box>
                                            <Box my={2}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.location_id && errors.location_id
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.location_id && errors.location_id
                                                    }
                                                    label="Location"
                                                    name="location_id"
                                                    select
                                                    onBlur={handleBlur}
                                                    // onChange={handleChange}
                                                    onChange={(e) => {
                                                        setFieldValue('location_id', e.target.value);
                                                        setLocationsName(e.target.selectedOptions[0].label)
                                                        handleFetchService(e.target.value, setFieldValue)
                                                    }}
                                                    value={values.location_id}
                                                    variant="outlined"
                                                    SelectProps={{ native: true }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                >
                                                    <option value="">-</option>
                                                    {locationList.data.length > 0 ? (
                                                        locationList.data.map((data, index) => (
                                                            <option key={index} value={data.id} label={data.location}>
                                                                {data.location}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option value="">No location added</option>
                                                    )}
                                                </TextField>
                                            </Box>

                                            <Box my={2}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.service_id && errors.service_id
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.service_id && errors.service_id
                                                    }
                                                    label="Services"
                                                    name="service_id"
                                                    select
                                                    onBlur={handleBlur}
                                                    // onChange={handleChange}
                                                    onChange={(e) => {
                                                        setFieldValue('service_id', e.target.value);
                                                        setServicesName(e.target.selectedOptions[0].label)
                                                        handleFetchWorker(e.target.value, setFieldValue)
                                                    }}
                                                    value={values.service_id}
                                                    variant="outlined"
                                                    SelectProps={{ native: true }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                >
                                                    <option value="">-</option>
                                                    {fetchServices.data.length > 0 ? (
                                                        fetchServices.data.map((data, index) => (
                                                            <option key={index} value={data.id} label={data.service}>
                                                                {data.service}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option value="">No service added</option>
                                                    )}
                                                </TextField>
                                            </Box>
                                            <Box my={2}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.worker_id && errors.worker_id
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.worker_id && errors.worker_id
                                                    }
                                                    label="ROD/DOD"
                                                    name="worker_id"
                                                    select
                                                    onBlur={handleBlur}
                                                    // onChange={handleChange}
                                                    onChange={(e) => {
                                                        setFieldValue('worker_id', e.target.value);
                                                        setWorkersName(e.target.selectedOptions[0].label)
                                                    }}
                                                    value={values.worker_id}
                                                    variant="outlined"
                                                    SelectProps={{ native: true }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                >
                                                    <option value="">-</option>
                                                    {fetchWorkers.data.length > 0 ? (
                                                        fetchWorkers.data.map((data, index) => (
                                                            <option key={index} value={data.user_id} label={data.workeruser.name}>
                                                                {data.workeruser.name}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option value="">No worker added</option>
                                                    )}
                                                </TextField>
                                            </Box>
                                            <Box my={2}>
                                                <DatePicker
                                                    fullWidth
                                                    disableToolbar
                                                    inputVariant="outlined"
                                                    label="Date"
                                                    name="date"
                                                    // helperText="No year selection"
                                                    onClick={() => setFieldTouched('date')}
                                                    onChange={date =>
                                                        setFieldValue('date', date)
                                                    }
                                                    value={values.date}
                                                    minDate={new Date()}
                                                />
                                            </Box>
                                            <Box my={2}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TimePicker
                                                            fullWidth
                                                            autoOk
                                                            label="Time From"
                                                            inputVariant="outlined"

                                                            onClick={() => setFieldTouched('time_from')}
                                                            onChange={e =>
                                                                setFieldValue('time_from', e)
                                                            }
                                                            value={values.time_from}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TimePicker
                                                            fullWidth
                                                            autoOk
                                                            label="Time From"
                                                            inputVariant="outlined"
                                                            onClick={() => setFieldTouched('time_to')}
                                                            onChange={e =>
                                                                setFieldValue('time_to', e)
                                                            }
                                                            value={values.time_to}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box>
                                                <Typography variant='h4' style={{ fontWeight: "bold" }}>
                                                    Patient Information
                                                </Typography>
                                            </Box>
                                            <Box my={2}>
                                                <TextField
                                                    error={Boolean(touched.patient_email && errors.patient_email)}
                                                    fullWidth
                                                    helperText={touched.patient_email && errors.patient_email}
                                                    label="Email"
                                                    name="patient_email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.patient_email}
                                                    variant="outlined"
                                                    multiline
                                                />
                                            </Box>
                                            <Box my={2}>
                                                <TextField
                                                    error={Boolean(touched.patient_name && errors.patient_name)}
                                                    fullWidth
                                                    helperText={touched.patient_name && errors.patient_name}
                                                    label="Name"
                                                    name="patient_name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.patient_name}
                                                    variant="outlined"
                                                    multiline
                                                />
                                            </Box>
                                            <Box my={2}>
                                                <TextField
                                                    error={Boolean(touched.patient_contact_no && errors.patient_contact_no)}
                                                    fullWidth
                                                    helperText={touched.patient_contact_no && errors.patient_contact_no}
                                                    label="Mobile Number"
                                                    name="patient_contact_no"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.patient_contact_no}
                                                    variant="outlined"
                                                    multiline
                                                />
                                            </Box>
                                            <Box my={2}>
                                                <TextField
                                                    error={Boolean(touched.patient_description && errors.patient_description)}
                                                    fullWidth
                                                    helperText={touched.patient_description && errors.patient_description}
                                                    label="Description"
                                                    name="patient_description"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.patient_description}
                                                    variant="outlined"
                                                    multiline
                                                    minRows={5}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box>
                                                <Typography variant='h4' style={{ fontWeight: "bold" }}>
                                                    Booking Review
                                                </Typography>
                                            </Box>
                                            <Box my={2}>
                                                <Table size='small'>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align='center' style={{ border: "1px solid #000" }}>Location</TableCell>
                                                            <TableCell align='center' style={{ border: "1px solid #000", fontWeight: "bold" }}>{locationsName}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align='center' style={{ border: "1px solid #000" }}>Services</TableCell>
                                                            <TableCell align='center' style={{ border: "1px solid #000", fontWeight: "bold" }}>{servicesName}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align='center' style={{ border: "1px solid #000" }}>Worker</TableCell>
                                                            <TableCell align='center' style={{ border: "1px solid #000", fontWeight: "bold" }}>{workersName}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align='center' style={{ border: "1px solid #000" }} rowSpan={2}>Date & Time</TableCell>
                                                            <TableCell align='center' style={{ border: "1px solid #000", fontWeight: "bold" }}>{moment(values.date).format('MMMM DD YYYY,')} {moment(values.time_from).format('h:mm a')}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align='center' style={{ border: "1px solid #000", fontWeight: "bold" }}>{moment(values.date).format('MMMM DD YYYY,')} {moment(values.time_to).format('h:mm a')}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box p={2} display="flex" alignItems="center">
                                    <Box flexGrow={1} />
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                        color="secondary"
                                    // className={classes.confirmButton}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </Box>
        </Page>
    );
}

export default AppointmentDashboard;
